import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { StarIcon } from "@heroicons/react/20/solid";
import { LinkButton } from ".";

const Testimonial = ({ t }) => {
  const className =
    "rounded-lg p-4 border border-gray-200 shadow-lg h-full grid overflow-hidden gap-3";
  return (
    <div className={className}>
      <div className="xl:row-span-5 lg:row-span-5 md:row-span-5 self-center">
        <GatsbyImage
          alt="Testimonial Image"
          className="rounded-md"
          image={getImage(t.Photo.localFile)}
        />
      </div>
      <div className="flex text-yellow-500 xl:row-end-1 xl:col-start-2 xl:col-span-2 lg:row-end-1 lg:col-start-2 lg:col-span-2 md:row-end-1 md:col-start-2 md:col-span-2 items-end">
        <StarIcon className="h-6 w-6" />
        <StarIcon className="h-6 w-6" />
        <StarIcon className="h-6 w-6" />
        <StarIcon className="h-6 w-6" />
        <StarIcon className="h-6 w-6" />
      </div>
      <div className=" xl:row-span-4 xl:col-span-2 lg:row-span-4 lg:col-span-2 md:row-span-4 md:col-span-2">
        <p className="font-normal">
          <i>"{t.Quote}"</i>
        </p>
        <p className="text-sm py-2">{t.Author}</p>
      </div>
    </div>
  );
};

const query = graphql`
  query {
    allStrapiTestimonials {
      edges {
        node {
          id
          Quote
          Author
          Photo {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
        }
      }
    }
  }
`;

const Testimonials = () => {
  const { allStrapiTestimonials } = useStaticQuery(query);
  const testimonials = allStrapiTestimonials.edges.map((x) => x.node);
  return (
    <div className="py-4 container">
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 lg:grid-cols-2">
        {testimonials.map((t, index) => (
          <Testimonial key={index} t={t} />
        ))}
      </div>
      <div className="mx-auto px-8 max-w-xl py-8">
        <LinkButton arrow to="/historias-de-exito">
          Ver historias de éxito
        </LinkButton>
      </div>
    </div>
  );
};

export default Testimonials;
