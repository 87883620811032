import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Testimonials from "../components/Testimonials";

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

const AutoplaySlider = withAutoplay(AwesomeSlider);

import {
  LockClosedIcon,
  ComputerDesktopIcon,
  CogIcon,
  ShieldCheckIcon,
  CurrencyDollarIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";

import {
  LinkButton,
  CTAAreYouReady,
  Layout,
  SEO,
  PressSimple,
  FeatureGridWithOffsetIcons,
  FeatureSolutions,
  FAQ,
} from "../components";

import { socialMedia } from "../config";
import Container from "../components/Container";

const benefits = [
  {
    name: "Cero Inversión Inicial",
    description:
      "Por tan sólo $0 mxn*, puedes empezar a disfrutar de energía solar bajo un esquema de renta mensual.",
    disclosure: "*Aplican restricciones. Consulta con tu Energy Expert.",
    icon: CurrencyDollarIcon,
  },
  {
    name: "Diseño Personalizado",
    description:
      "Elegimos el tamaño y la ubicación de tu sistema fotovoltaico de acuerdo a tus necesidades energéticas, la cantidad de sol y el tamaño de tu techo.",
    icon: PencilIcon,
  },
  {
    name: "Garantía",
    description:
      "Tenemos la garantía de producción más alta del mercado: Si tu sistema no produce lo acordado, te reembolsamos.",
    icon: ShieldCheckIcon,
  },
  {
    name: "Monitoreo",
    description:
      "Supervisamos 24/7 que el sistema solar esté funcionando y dándote los resultados que mereces.",
    icon: ComputerDesktopIcon,
  },
  {
    name: "Trámites ante CFE",
    description:
      "Desde el papeleo hasta los permisos, nos encargaremos de todos los todos los aspectos para la instalación de tu sistema.",
    icon: LockClosedIcon,
  },
  {
    name: "Mantenimiento",
    description:
      "Nos aliamos con las mejores cuadrillas de instaladores en cada ciudad para asegurar que tu sistema esté en óptimas condiciones. Además, contamos con seguro contra daños de hasta $1,000,000 USD.",
    icon: CogIcon,
  },
];

const IndexPage = ({ data }) => {
  const faqs = data.allStrapiFaqs.edges;
  const metadata = data.site.siteMetadata;

  const findSocialMedia = (slug) => {
    const founded = socialMedia.find((element) => element.slug === slug);
    return founded.href;
  };

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: metadata.title,
    url: metadata.siteUrl,
    logo: metadata.image,
    sameAs: [
      findSocialMedia("facebook"),
      findSocialMedia("twitter"),
      findSocialMedia("instagram"),
      findSocialMedia("youtube"),
      findSocialMedia("linkedin"),
    ],
  };

  return (
    <Layout>
      <SEO
        title="Servicio de energía solar sin inversión"
        description="Servicio premium de energía solar sin inversión para hogares y negocios en México. La suscripción a Bright incluye instalación de paneles solares, mantenimiento, monitoreo y garantía de producción."
        schemaMarkup={schemaMarkup}
      />
      {/* Hero Start */}
      <Container className="py-16 bg-white">
        <div className="flex w-full lg:w-1/2 justify-center lg:justify-start items-center px-6 text-center lg:text-left">
          <div>
            <h1 className="text-3xl sm:text-2xl text-blue-500 font-bold">
              <span className="block">
                Disfruta los beneficios de adquirir paneles solares
              </span>
              <span className="text-3xl sm:text-4xl block text-yellow-500 mt-1 font-bold uppercase">
                ¡Sin tener que comprarlos!
              </span>
            </h1>
            <p className="mt-3 md:mt-5 text-lg md:text-lg text-gray-700">
              Nosotros invertimos en el sistema fotovoltaico por ti.
            </p>
            <p className="mt-3 md:mt-5 text-lg md:text-lg text-gray-700">
              Reduce tu recibo de luz <b>hasta un 98%</b> y solo paga una
              mensualidad por la energía que usas.
            </p>
            <div className="mt-8 overflow-hidden">
              <dl className="-mx-8 -mt-8 flex flex-wrap justify-center lg:justify-start">
                <div className="flex flex-col px-8 pt-8">
                  <dd className="order-1 text-2xl font-bold text-blue-500 sm:text-3xl">
                    +4,000
                  </dd>
                  <dt className="order-2 text-base font-medium text-gray-700">
                    Suscriptores
                  </dt>
                </div>
                <div className="flex flex-col px-8 pt-8">
                  <dd className="order-1 text-2xl font-bold text-blue-500 sm:text-3xl">
                    +20
                  </dd>
                  <dt className="order-2 text-base font-medium text-gray-700">
                    Estados
                  </dt>
                </div>
                <div className="flex flex-col px-8 pt-8">
                  <dd className="order-1 text-2xl font-bold text-blue-500 sm:text-3xl">
                    +60
                  </dd>
                  <dt className="order-2 text-base font-medium text-gray-700">
                    Ciudades
                  </dt>
                </div>
              </dl>
            </div>
            <div className="flex flex-col mx-auto md:flex-row mt-8 justify-center lg:justify-start space-y-6 md:space-y-0 md:space-x-6">
              <LinkButton shadow arrow size="large" to="/cotizar">
                Solicita una cotización
              </LinkButton>
            </div>
          </div>
        </div>
        <div className="flex w-full lg:w-1/2 items-center px-8 mt-8 lg:mt-0 -z-0">
          <AutoplaySlider
            play={true}
            cancelOnInteraction={false} // should stop playing on user interaction
            interval={3000}
            bullets={false}
            organicArrows={false}
            infinite={true}
            mobileTouch={true}
          >
            <div>
              <StaticImage
                alt="instalacion montañas"
                height={450}
                src="../images/instalaciones-1.jpg"
              />
            </div>
            <div>
              <StaticImage
                alt="instalacion campo"
                height={450}
                src="../images/instalaciones-2.jpg"
              />
            </div>
            <div>
              <StaticImage
                alt="instalacion con flores"
                height={450}
                src="../images/instalaciones-3.jpg"
              />
            </div>
            <div>
              <StaticImage
                alt="instalacion soleada"
                height={450}
                src="../images/instalaciones-4.jpg"
              />
            </div>
          </AutoplaySlider>
        </div>
      </Container>
      {/* Hero End */}

      {/* Features Start */}
      <FeatureGridWithOffsetIcons
        title="¿Por qué elegir Bright?"
        description="Al suscribirte a nuestro Servicio Premium, obtienes:"
        features={benefits}
      />
      {/* Features End */}

      <Container className="py-16 bg-white">
        <div className="flex w-full lg:w-1/2 items-center justify-center lg:justify-start px-8 mt-6 lg:mt-0">
          <StaticImage
            alt="Casa con paneles solares con persona alcanzado el sol"
            height={450}
            src="../images/illustrations/concept_house_with_solar_panels.png"
          />
        </div>
        <div className="flex w-full lg:w-1/2 justify-center items-center px-8 text-center lg:text-left">
          <div>
            <h2 className="mt-8 md:mt-5 text-3xl text-blue-500 font-bold uppercase">
              ¿Quiénes somos?
            </h2>
            <p className="mt-5 md:mt-5 text-lg text-gray-700">
              Somos pioneros en la adopción de energía solar para hogares en
              Latinoamérica. Hoy en día, somos la compañía de generación
              distribuida más grande en México para casas y negocios.
            </p>
            <div className="flex mt-8 justify-center lg:justify-start">
              <LinkButton outlined arrow to="/nosotros">
                Más acerca de Bright
              </LinkButton>
            </div>
          </div>
        </div>
      </Container>
      {/*Solutions start */}
      <FeatureSolutions />
      {/* Solutions End */}

      <Container className="py-16 bg-white">
        <div className="flex flex-col">
          <h2 className="mt-2 text-3xl font-bold text-blue-500 text-center uppercase">
            ¿Qué dicen nuestros clientes?
          </h2>
          <Testimonials />
        </div>
      </Container>

      <PressSimple background="bg-gray-50" />

      <div className="bg-white-50">
        <div className="flex flex-col mx-auto py-16 justify-center max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
          <h2 className="text-3xl font-bold text-blue-500 text-center uppercase">
            Preguntas frecuentes
          </h2>
          <div className="mx-auto py-12 px-8 max-w-2xl flex-col space-y-6">
            {faqs.map((faq, index) => (
              <FAQ key={index} faq={faq.node} />
            ))}
          </div>
          <div className="mx-auto px-8 max-w-2xl">
            <LinkButton outlined arrow to="/preguntas-frecuentes">
              Ver más
            </LinkButton>
          </div>
        </div>
      </div>

      <CTAAreYouReady />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allStrapiFaqs(filter: { display_on_homepage: { eq: true } }) {
      edges {
        node {
          question
          answer
        }
      }
    }
    site {
      siteMetadata {
        title
        defaultImage: image
        siteUrl
      }
    }
  }
`;
